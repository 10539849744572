import { Routes } from '@angular/router';
import { AuthRedirectGuard } from './guards/auth-guard';
import { AuthResolver } from './services/auth-resolver';

export const routes: Routes = [
    {
        path: 'upload',
        loadComponent: () => import('./components/upload/upload.component').then((m) => m.UploadComponent),
        canActivate: [AuthRedirectGuard],
        resolve: { auth: AuthResolver },
    },
    {
        path: 'scanned',
        loadComponent: () => import('./components/scanned/scanned.component').then((m) => m.ScannedComponent),
        canActivate: [AuthRedirectGuard],
        resolve: { auth: AuthResolver },
    },
    {
        path: 'reviewed',
        loadComponent: () => import('./components/reviewed/reviewed.component').then((m) => m.ReviewedComponent),
        canActivate: [AuthRedirectGuard],
        resolve: { auth: AuthResolver },
    },
    {
        path: 'library',
        loadComponent: () => import('./components/library/library.component').then((m) => m.LibraryComponent),
        canActivate: [AuthRedirectGuard],
        resolve: { auth: AuthResolver },
    },
    {
        path: 'settings',
        loadComponent: () => import('./components/organization/organization.component').then((m) => m.OrganizationComponent),
        canActivate: [AuthRedirectGuard],
        resolve: { auth: AuthResolver },
    },
    {
        path: 'login',
        loadComponent: () => import('./components/login/login.component').then((m) => m.LoginComponent),
    },
    {
        path: 'register',
        loadComponent: () => import('./components/register/register.component').then((m) => m.RegisterComponent),
    },
    {
        path: 'logout',
        loadComponent: () => import('./components/logout/logout.component').then((m) => m.LogoutComponent),
    },
    { path: '**', redirectTo: 'login' },
];
