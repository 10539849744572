import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthResolver implements Resolve<boolean> {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    resolve(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.authService.isInitialized$.subscribe((isInitialized) => {
                if (isInitialized) {
                    observer.next(true);
                    observer.complete();
                } else {
                    this.authService.initializeAccount().then(() => {
                        observer.next(true);
                        observer.complete();
                    });
                }
            });
        });
    }
}
