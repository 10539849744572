import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { AuthCookiesService } from './auth-cookies.service';
import { DbService } from './db-service';
import { environment } from '../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { CustomerFilter } from '../../enums/customer-filter.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public userAccountInfo: AccountInfo | null = null;
    public isUserLoggedIn = false;
    public isAdmin = false;
    public customerFilter!: CustomerFilter;

    public activeAccountSubject = new BehaviorSubject<AccountInfo | null>(null);
    public activeAccount$ = this.activeAccountSubject.asObservable();

    private isInitializedSubject = new BehaviorSubject<boolean>(false);
    public isInitialized$ = this.isInitializedSubject.asObservable();

    constructor(
        private msalService: MsalService,
        private router: Router,
        private authCookieService: AuthCookiesService,
        private dbService: DbService
    ) {}

    public async initializeAccount(): Promise<void> {
        return new Promise((resolve) => {
            const activeAccount = this.msalService.instance.getActiveAccount();
            if (!activeAccount) {
                this.msalService.instance
                    .handleRedirectPromise()
                    .then(async (response) => {
                        if (response && response.account) {
                            await this.setAccountInfo(response.account);
                        } else {
                            const url = window.location.hash;
                            const idToken = new URLSearchParams(url.substring(1)).get('id_token');
                            if (idToken) {
                                this.authCookieService.setIdTokenCookie(idToken);
                                const token = jwtDecode(idToken);
                                this.dbService.registerOrganization(token as AccountInfo).subscribe(async () => {
                                    this.router.navigate(['logout']);
                                    await this.logIn();
                                });
                            }
                        }
                        this.isInitializedSubject.next(true);
                        resolve();
                    })
                    .catch(async () => {
                        await this.clearStorage();
                        this.router.navigate(['login']);
                        this.isInitializedSubject.next(true);
                        resolve();
                    });
            } else {
                this.setAccountInfo(activeAccount)
                    .then(() => {
                        this.isInitializedSubject.next(true);
                        resolve();
                    })
                    .catch(() => {
                        this.isInitializedSubject.next(true);
                        resolve();
                    });
            }
        });
    }

    public async registerOrganization(): Promise<void> {
        const loginRequest: RedirectRequest = {
            scopes: ['openid', 'profile', 'email'],
            authority: `https://${environment.azureAdB2c.tenantName}.b2clogin.com/${environment.azureAdB2c.tenantName}.onmicrosoft.com/${environment.azureAdB2c.signUp}`,
            redirectStartPage: `${environment.azureAdB2c.redirectUri}/logout`,
        };

        await this.clearStorage();

        this.msalService.loginRedirect(loginRequest);
    }

    public async logIn(): Promise<void> {
        if (this.isUserLoggedIn) {
            this.router.navigate(['upload']);
            return;
        }

        await this.clearStorage();

        this.msalService.loginRedirect({
            scopes: ['openid', 'profile', 'email'],
            redirectStartPage: `${environment.azureAdB2c.redirectUri}/upload`,
        });
    }

    public async logOut(): Promise<void> {
        await this.clearStorage();

        this.msalService.logout({
            account: this.activeAccountSubject.value,
            postLogoutRedirectUri: `${environment.azureAdB2c.redirectUri}/login`,
        });
    }

    public async forgotPassword(): Promise<void> {
        const forgotPasswordRequest: RedirectRequest = {
            scopes: ['openid', 'profile', 'email'],
            authority: `https://${environment.azureAdB2c.tenantName}.b2clogin.com/${environment.azureAdB2c.tenantName}.onmicrosoft.com/${environment.azureAdB2c.forgotPassword}`,
            redirectStartPage: `${environment.azureAdB2c.redirectUri}/upload`,
        };
        await this.clearStorage();
        this.msalService.loginRedirect(forgotPasswordRequest);
    }

    private setAccountInfo(account: AccountInfo): Promise<void> {
        return new Promise<void>((resolve) => {
            if (account) {
                const roles = account.idTokenClaims?.['extension_Roles'] as string[] | undefined;
                const isAdmin = !!(roles && roles.includes('organizationAdmin'));
                this.isAdmin = isAdmin;
                this.customerFilter = this.isAdmin ? CustomerFilter.OrganizationLevel : CustomerFilter.UserLevel;
                
                this.activeAccountSubject.next(account);
                this.msalService.instance.setActiveAccount(account);
                this.isUserLoggedIn = true;

                if (account.idToken) {
                    this.authCookieService.setIdTokenCookie(account.idToken);
                }
                const userAlreadyRegistered = localStorage.getItem('registeredUser');

                if (account?.idTokenClaims && account?.idTokenClaims['newUser'] && !userAlreadyRegistered) {
                    this.isUserLoggedIn = false;
                    this.dbService.registerOrganization(account).subscribe(async () => {
                        localStorage.setItem('registeredUser', 'yes');
                        await this.logOut();
                        this.router.navigate(['login']);
                    });
                } else {
                    resolve();
                }
            } else {
                this.router.navigate(['login']);
                resolve();
            }
        });
    }

    private async clearStorage(): Promise<void> {
        this.activeAccountSubject.next(null);
        localStorage.clear();
        sessionStorage.clear();
        this.authCookieService.removeIdTokenCookie();
        await this.msalService.instance.clearCache();
    }
}
